import { Injectable, Injector } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import {
    ApplicationDto,
    ApplicationJournalRecordDto,
    ApplicationServiceProxy,
    ApplicationStatus,
    ApplicationSupplementalClaimDto,
    ApplicationType,
    ApplicationUploadFilesInput,
    AssignApplicationInput,
    AssignApplicationOutput,
    CreateApplicationJournalRecordInput,
    DlaStatus,
    EhcStatus,
    EvaluationDto,
    EvaluationInput,
    GeneralDocumentGroupDto,
    GeneralDocumentsOutput,
    GeneralFileDto,
    GeneralFileInfoDto,
    GeneralResultDocumentInput,
    LiaStatus,
    NotConsiderApplicationInputDto,
    PersonApplicationSearchDto,
    RejectApplicationInputDto,
    RequestForSupplementalInformationInput,
    WithdrawApplicationInputDto
} from '@shared/service-proxies/service-proxies';
import { AppConsts } from '@shared/AppConsts';
import { AppComponentBase } from '@shared/common/app-component-base';
import { CodeService } from '@app/shared/common/code/code.service';
import { SelectItem } from 'primeng/api';
import { SimpleFile } from '../common/simple-file-upload/simple-file-upload.component';

export interface ApplicationStateData {
    notConsiderMessage: string;
    notConsiderFiles: SimpleFile[];
    withdrawMessage: string;
    withdrawFiles: SimpleFile[];
    rejectionMessage: string;
    rejectionFiles: SimpleFile[];
}

@Injectable()
export class ApplicationService extends AppComponentBase {

    private statusChangedSource = new ReplaySubject<ApplicationStatus>(1);
    public statusChanged$ = this.statusChangedSource.asObservable();
    private evaluationChangedSource = new ReplaySubject<void>(1);
    public evaluationChanged$ = this.evaluationChangedSource.asObservable();

    notConsiderMessage: string;
    private applicationStateData: ApplicationStateData;
    protected _application: ApplicationDto;
    protected _caseId = '';
    applicationStatus: SelectItem[];
    constructor(
        injector: Injector,
        protected codeService: CodeService,
        private applicationService: ApplicationServiceProxy,
    ) {
        super(injector);
    }

    setApplicationStateData(applicationStateData: ApplicationStateData){
        this.applicationStateData = applicationStateData;
    }
    getApplicationStateData(): ApplicationStateData{
        if (!this.applicationStateData){
            this.applicationStateData = {
                notConsiderMessage: '',
                notConsiderFiles: [],
                withdrawMessage: '',
                withdrawFiles:  [],
                rejectionMessage: '',
                rejectionFiles:  [],
            };
        }
        return this.applicationStateData;
    }

    areAllMandatoryDocumentsUploaded(documents: GeneralDocumentGroupDto[]) {
        if (documents == null) return false;
        for (var i = 0; i < documents.length; i++) {
            if (documents[i].isMandatory && documents[i].files.length === 0) {
                return false;
            }
        }
        return true;
    }

    getApplication(caseId: string): Observable<ApplicationDto> {
        this._caseId = caseId;
        return this.applicationService.getApplication(caseId).pipe(tap((output: ApplicationDto) => {
            this._application = output;
            this.statusChangedSource.next(output.status);
        }));
    }

    closeApplication(): void {
        this._application = null;
        this._caseId = '';
    }

    get application(): ApplicationDto {
        return this._application;
    }

    deleteApplication(): Observable<void> {
        return this.applicationService.deleteApplication(this._caseId);
    }

    isInStatus(expectedStatus: ApplicationStatus): boolean {
        return this._application.status === expectedStatus;
    }

    get status(): ApplicationStatus {
        return this._application.status;
    }

    get caseId(): string {
        return this._caseId;
    }

    get type(): ApplicationType {
        return this._application.type;
    }

    assign(input: AssignApplicationInput): Observable<AssignApplicationOutput> {
        return this.applicationService.assignApplication(input);
    }

    unassign(caseId: string): Observable<void> {
        return this.applicationService.unassignApplication(caseId);
    }

    withdrawnApplication(input: WithdrawApplicationInputDto): Observable<void> {
        return this.applicationService.withdrawApplication(this.caseId, input).pipe(tap(() => {
            this._application.status = ApplicationStatus.Withdrawn;
            this.statusChangedSource.next(ApplicationStatus.Withdrawn);
        }));
    }

    reactivateApplication(caseId: string = this._caseId): Observable<void> {
        return this.applicationService.reactivateApplication(caseId).pipe(tap(() => {
            this._application.status = ApplicationStatus.Submitted;
        }));
    }

    notConsiderApplication(input: NotConsiderApplicationInputDto): Observable<void> {
        return this.applicationService.notConsiderApplication(this.caseId, input).pipe(tap(() => {
            this._application.status = ApplicationStatus.NotConsidered;
            this.statusChangedSource.next(ApplicationStatus.NotConsidered);
        }));
    }

    startPreEvaluation(caseId: string): Observable<void> {
        return this.applicationService.startPreEvaluation(caseId).pipe(tap(() => {
            this._application.status = ApplicationStatus.UnderPreEvaluation;
            this.statusChangedSource.next(ApplicationStatus.UnderPreEvaluation);
        }));
    }

    startEvaluation(caseId: string): Observable<void> {
        return this.applicationService.startEvaluation(caseId).pipe(tap(() => {
            this._application.status = ApplicationStatus.UnderEvaluation;
            this.statusChangedSource.next(ApplicationStatus.UnderEvaluation);
        }));
    }

    getEvaluation(step: number): EvaluationDto {
        if ((this._application.status !== ApplicationStatus.UnderPreEvaluation && this._application.status !== ApplicationStatus.UnderEvaluation)
            || this.application.evaluations == null) {
            return null;
        }

        return this.application.evaluations.find(e => e.step === step);
    }

    getEvaluationForSupplementalClaim(step: number): EvaluationDto {
        if (this._application.status !== ApplicationStatus.SupplementalClaim) {
            return null;
        }

        return this.application.evaluations.find(e => e.step === step);
    }

    reviewActionPossible(): boolean {
        switch (this._application.status) {
            case ApplicationStatus.FormallyEvaluated:
            case ApplicationStatus.Granted:
            case ApplicationStatus.Denied:
            case ApplicationStatus.NotConsidered:
            case ApplicationStatus.Withdrawn:
            case ApplicationStatus.Revoked:
            case ApplicationStatus.Submitted:
                return false;
            default:
                return true;
        }
    }

    getEvaluations(): EvaluationDto[] {
        if (this._application.status !== ApplicationStatus.UnderPreEvaluation && this._application.status !== ApplicationStatus.UnderEvaluation) {
            return null;
        }

        return this.application.evaluations;
    }

    isEvaluationFinished(): boolean {
        if (this._application.evaluations == null) {
            return false;
        }

        return this.application.evaluations.every(x => x.okayId == AppConsts.codes.evaluationStatus.ok ||
            x.okayId == AppConsts.codes.evaluationStatus.notOk ||
            x.okayId == AppConsts.codes.evaluationStatus.internalClarificationRequired);
    }

    isUnderConstruction(): boolean {
        switch (this.application?.status) {
            case ApplicationStatus.UnderConstruction:
              return true;
            default:
              return false;
            }
    }

    isUnderPreEvaluation(): boolean {
        return this.application?.status === ApplicationStatus.UnderPreEvaluation;
    }

    isUnderEvaluation(): boolean {
        return this.application?.status === ApplicationStatus.UnderEvaluation;
    }

    canReject(): boolean {
        if (!this.permission.isGranted('Pages.Authority.Applications')) {
          return false;
        }

        switch (this.application?.status) {
        case ApplicationStatus.Submitted:
        case ApplicationStatus.UnderEvaluation:
        case ApplicationStatus.UnderPreEvaluation:
          return true;
        default:
          return false;
        }
      }

    canNotConsider(): boolean {
        if (this.permission.isGranted('Pages.Authority.Applications')) {
            switch (this.application?.status) {
              case ApplicationStatus.UnderPreEvaluation:
              case ApplicationStatus.UnderEvaluation:
              case ApplicationStatus.SupplementalClaim:
                return true;
              default:
                return false;
            }
        }

        return false;
    }

    canReactivate(): boolean {
        if (this.permission.isGranted('Pages.Authority.Applications')) {
            switch (this.application?.status) {
              case ApplicationStatus.NotConsidered:
              case ApplicationStatus.Denied:
              case ApplicationStatus.Withdrawn:
                return true;
              default:
                return false;
            }
        }

        return false;
    }

    canWithdraw(): boolean {
        switch (this.application?.status) {
            case ApplicationStatus.UnderConstruction:
            case ApplicationStatus.Submitted:
                return true;
        }

        let isAuthority = this.permission.isGranted('Pages.Authority.Applications');
        if (isAuthority) {
            switch (this.application?.status) {
                case ApplicationStatus.UnderPreEvaluation:
                case ApplicationStatus.UnderEvaluation:
                  return true;
            }
        }
        return false;
    }

    updateEvaluation(evaluation: EvaluationDto): Observable<void> {
        return this.applicationService.updateEvaluation(new EvaluationInput({ caseId: this._application.caseId, evaluation: evaluation, message: this.getCurrentSupplementalClaim()?.message })).pipe(tap(() => {
            const item = this._application.evaluations.find(e => e.step === evaluation.step);
            const index = this._application.evaluations.indexOf(item);
            this._application.evaluations[index] = evaluation;
            const okay = this.isFormallyOkay();
            this.evaluationChangedSource.next();
        }));
    }

    requestForSupplementalInformation(message: string, isReminder: boolean = false): Observable<ApplicationDto> {
        return this.applicationService.requestForSupplementalInformation(new RequestForSupplementalInformationInput({ caseId: this._application.caseId, message: message, isReminder: isReminder }))
            .pipe(tap((app) => {
                this._application = app;
                this.statusChangedSource.next(this._application.status);
            }));
    }


    getCurrentSupplementalClaim(): ApplicationSupplementalClaimDto {
        return this._application.supplementaryClaims.find(sc => sc.applicationHistoryId === null);
    }

    hasUndeliveredSupplementalClaims(): boolean {
        return (this._application.status === ApplicationStatus.UnderPreEvaluation || this._application.status === ApplicationStatus.UnderEvaluation) && this.isFormallyNotOkay();
    }

    rejectApplication(input: RejectApplicationInputDto): Observable<void> {
        return this.applicationService.rejectApplication(this._application.caseId, input);
    }

    reviseApplication(): Observable<void> {
        return this.applicationService.setApplicationUnderConstruction(this._application.caseId);
    }

    isFormallyOkay(): boolean {
        return this._application.evaluations?.filter(e => e.okayId !== AppConsts.codes.evaluationStatus.ok).length === 0;
    }

    isFormallyOkayOrInternalClarificationRequired(): boolean {
        return this._application.evaluations?.filter(e =>
            e.okayId !== AppConsts.codes.evaluationStatus.ok &&
            e.okayId !== AppConsts.codes.evaluationStatus.internalClarificationRequired).length === 0;
    }

    anyStepInternalClarificationRequired(): boolean {
        return this._application.evaluations?.filter(e =>
            e.okayId === AppConsts.codes.evaluationStatus.internalClarificationRequired).length > 0;
    }

    anyStepNotOkay(): boolean {
        return this._application.evaluations?.filter(e =>
            e.okayId === AppConsts.codes.evaluationStatus.notOk).length > 0;
    }

    isFormallyNotOkay(): boolean {
        return this._application.evaluations?.filter(e =>
            e.okayId === AppConsts.codes.evaluationStatus.notOk ||
            e.okayId === AppConsts.codes.evaluationStatus.internalClarificationRequired).length > 0;
    }

    uploadResultDocument(input: GeneralResultDocumentInput): Observable<void> {
        return this.applicationService.uploadResultDocument(input);
    }

    private checkOverallEvaluationStatus(oldOkay: boolean) {
        const okay = this.isFormallyOkay();
        if (okay !== oldOkay) {
        }
    }

    formallyEvaluated(): Observable<void> {
        return this.applicationService.formallyEvaluateApplication(this._application.caseId);
    }

    accept(resultFiles: GeneralFileDto[]): Observable<void> {
        return this.applicationService.acceptApplication(this._application.caseId, resultFiles);
    }

    getNextEvaluationStepUrl(evaluation: EvaluationDto): string {
        const baseUrl = AppConsts.authorityBaseUrl + `/applications/${this.getNavigationContext()}/${this.caseId}/`;

        if (this.isEvaluationFinished() && (this.application.status === ApplicationStatus.UnderPreEvaluation || this.application.status === ApplicationStatus.UnderEvaluation)) {
            if (this.application.type == ApplicationType.Lia) {
                return baseUrl + 'wizard/' + this.getUrlForStep(LiaStatus.FinishReview);
            } else if  (this.application.type == ApplicationType.Dla) {
                return baseUrl + 'wizard/' + this.getUrlForStep(DlaStatus.OperatingLicence);
            } if  (this.application.type == ApplicationType.Ehc) {
                return baseUrl + 'wizard/' + this.getUrlForStep(EhcStatus.FinishReview);
            }

        }

        for (let index = 0; index < this._application.evaluations.length; index++) {
            if ((evaluation === null || this._application.evaluations[index].order > evaluation.order) &&
                (!this._application.evaluations[index].okayId ||
                    this._application.evaluations[index].okayId !== AppConsts.codes.evaluationStatus.ok)) {
                return baseUrl + 'wizard/' + this.getUrlForStep(this._application.evaluations[index].step);
            }
        }

        if (evaluation === null && this.type === ApplicationType.Lia) {
            return baseUrl + 'wizard/' + this.getUrlForStep(LiaStatus.Person);
        } else if (evaluation === null && this.type === ApplicationType.Dla) {
            return baseUrl + 'wizard/' + this.getUrlForStep(DlaStatus.Daycare);
        } else if (evaluation === null && this.type === ApplicationType.Ehc) {
            return baseUrl + 'wizard/' + this.getUrlForStep(EhcStatus.SelfEmployment);
        }

        return null;
    }

    getResultDocuments(caseId = this._caseId): Observable<GeneralFileInfoDto[]> {
        return this.applicationService.getResultDocuments(caseId).pipe(map((output) => output.resultDocuments));
    }

    getResultFile(caseId: string, id: number): Observable<GeneralFileDto> {
        return this.applicationService.getResultFile(caseId, id);
    }

    getHistoryStatusDocument(caseId: string, id: number): Observable<GeneralFileDto> {
        return this.applicationService.getApplicationHistoryStatusDocument(caseId, id);
    }

    getApplicationAuthorityHistoryDocument(caseId: string, id: number): Observable<GeneralFileDto> {
        return this.applicationService.getApplicationAuthorityHistoryDocument(caseId, id);
    }

    getUrlForStep(step: number): string {
        return null;
    }

    deleteResultDocument(docId: number): Observable<void> {
        return this.applicationService.deleteResultDocument(this.caseId, docId);
    }

    getCssClassForStatusId(statusId: ApplicationStatus, prefix: string): string {
        if (statusId === ApplicationStatus.Granted) {
            return prefix + 'success';
        } else if (statusId === ApplicationStatus.Withdrawn || statusId === ApplicationStatus.NotConsidered) {
            return prefix + 'secondary';
        } else if (statusId === ApplicationStatus.Denied) {
            return prefix + 'danger';
        } else if (statusId === ApplicationStatus.SupplementalClaim) {
            return prefix + 'warning';
        }

        return prefix + 'secondary';
    }

    getStatusDescription(statusId: ApplicationStatus) {
        if (!this.applicationStatus) {
            this.applicationStatus = this.codeService.getEnumForDropdown('ApplicationStatus', ApplicationStatus, false);
        }

        if (!abp.auth.isGranted('Pages.Authority.Applications') &&
            (statusId === ApplicationStatus.UnderEvaluation || statusId === ApplicationStatus.FormallyEvaluated)) {
            return this.l('MySironaLiaApplicationStatusFormallyEvaluated');
        } else {
            return this.le('applicationStatus', statusId);
        }
    }

    displaySubmittedCount(x: ApplicationDto | PersonApplicationSearchDto) : string {
        if (x.status === ApplicationStatus.Submitted) {
            return `(${x.submittedCount})`;
        }
        return ``;
    }

    setStepToReview(stepId: number) {
        // update warning mark from supplemental claim to okay
        if (this._application.status === ApplicationStatus.SupplementalClaim) {
            const step = this._application.evaluations.find(x => x.step === stepId);
            if (step.okayId === AppConsts.codes.evaluationStatus.ok ||
                step.okayId === AppConsts.codes.evaluationStatus.notOk ||
                step.okayId === AppConsts.codes.evaluationStatus.internalClarificationRequired) {
                this.applicationService.setStepToReview(this._application.caseId, stepId).subscribe(() => {
                    const step = this._application.evaluations.find(x => x.step === stepId);
                    step.okayId = null;
                });
            }
        }
    }

    getSupplementalClaimAnnexFilesOfStep(applicationWizardStep: number, caseId = this._caseId): Observable<GeneralDocumentGroupDto[]> {
        return this.applicationService.getSupplementalClaimAnnexFilesOfStep(caseId, applicationWizardStep).pipe(tap((output: GeneralDocumentsOutput) => {
            this.application.authorityDocuments = output.documents;
        })).pipe(map((output: GeneralDocumentsOutput) => output.documents));
    }

    getAuthorityFile(fileId: number): Observable<GeneralFileDto> {
        return this.applicationService.getAuthorityFile(this._caseId, fileId)
    }

    uploadAuthorityFiles(input: ApplicationUploadFilesInput, wizardStep: number): Observable<GeneralFileInfoDto[]> {
        input.caseId = this._caseId;
        return this.applicationService.uploadAuthorityFiles(wizardStep, input);
    }

    deleteAuthorityFile(fileId: number): Observable<void> {
        return this.applicationService.deleteAuthorityFile(fileId, this._caseId);
    }

    deleteAuthorityFiles(wizardStep: number): Observable<void> {
        return this.applicationService.deleteAuthorityFiles(wizardStep, this._caseId);
    }

    setApplicationsInSubmitted(caseIds: string[]): Observable<void> {
        return this.applicationService.setApplicationsInSubmitted(caseIds);
    }

    getJournalRecords(caseId: string): Observable<ApplicationJournalRecordDto[]> {
        return this.applicationService.getJournalRecords(caseId);
    }

    getLatestApplicationJournalRecord(caseId: string, wizardStep: number): Observable<ApplicationJournalRecordDto> {
        return this.applicationService.getLatestApplicationJournalRecord(caseId, wizardStep);
    }

    createJournalRecord(input: CreateApplicationJournalRecordInput): Observable<ApplicationJournalRecordDto> {
        return this.applicationService.createJournalRecord(input);
    }

    deleteJournalRecord(caseId: string, recordId: number): Observable<void> {
        return this.applicationService.deleteJournalRecord(caseId, recordId);
    }

    getNavigationContext(type: ApplicationType = this.type): string {
        switch (type) {
            case ApplicationType.Lia:
                return 'lia';
            case ApplicationType.Dla:
                return 'dla';
            case ApplicationType.Ehc:
                return 'ehc';
            default:
                return '';
        }
    }
}
